import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

const PsTextSearchBox = (props) => {
  const [value, setValue] = useState(null);

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value != null) {
      const timeoutId = setTimeout(() => callPropsFuc(), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  const callPropsFuc = () => {
    if (props.onSuccess) props.onSuccess(value);
  };

  return (
    <>
      <InputGroup size={props.size || "sm"}>
        <InputGroup.Text>
          <i className="fa-solid fa-search"></i>
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={props.placeholder || "Search.."}
          size={props.size || "sm"}
          onChange={(e) => handleOnChange(e)}
        />
      </InputGroup>
    </>
  );
};

export default PsTextSearchBox;
