import React, { useState } from "react";
import { Select } from "antd";

const PsSelect = (props) => {
  const onChange = (value) => {
    if (props.onChange) props.onChange(value);
  };

  const onSearch = (value) => {
    if (props.onSearch) props.onSearch(value);
  };

  const labelValue = (item) => {
    let lableField = props.lableField;
    if (lableField && typeof lableField == "function") {
      return props.lableField(item);
    } else {
      return lableField && item[lableField] ? item[lableField] : item.name;
    }
  };
  const options = () => {
    let rv = [];
    if (props.withNull)
      rv.push({
        value: "",
        label: "None",
      });

    if (props.dataSource) {
      props.dataSource.map((item, i) => {
        rv.push({
          value:
            props.valueField && item[props.valueField]
              ? item[props.valueField]
              : item.id,
          label: labelValue(item),
        });
      });
    }

    return rv;
  };
  //console.log(props);
  return (
    <>
      <Select
        showSearch
        placeholder={props.placeholder || "-Select-"}
        optionFilterProp="children"
        size={props.size || "small"}
        style={props.style || { width: "100%" }}
        value={props.value}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
        onSearch={onSearch}
        options={options()}
        mode={props.mode}
        allowClear={props.allowClear}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
    </>
  );
};

export default PsSelect;
