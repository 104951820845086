import React, { useState } from "react";

const PsLabel = (props) => {
  return (
    <label className="control-label">
      {props.text} {props.required && <span className="text-danger">*</span>}
    </label>
  );
};

export default PsLabel;
